<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.request_sponsored_training') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <b-row>
              <b-col md="12">
                  <iq-card>
                      <template v-slot:headerTitle>
                          <h4 class="card-title">{{$t('portal.request_sponsored_training')}}</h4>
                      </template>
                      <template v-slot:body>
                        <b-overlay :show="loading">
                          <b-row>
                            <b-col lg="12" sm="12">
                              <!-- <div>
                                <b-alert show dismissible variant="success" v-if="showMessage">
                                  {{$t('portal.sponsored_alert')}}
                                </b-alert>
                              </div> -->
                              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                                <b-form id="form" @submit.prevent="handleSubmit(createData)">
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                      <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('portal.applicant_information')}}</legend>
                                        <b-row class="p-3">
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Profession Type" vid="p_profession_type" rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="p_profession_type"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                <template v-slot:label>
                                                  {{ $t('portal.applicant_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.p_profession_type"
                                                    :options="professionTypeList"
                                                    @input="traineeDetailsReset()"
                                                    id="p_profession_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                  <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm=""></b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Name (En)" vid="applicant_name" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="applicant_name"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.applicant_name')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="applicant_name"
                                                  v-model="formData.applicant_name"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="applicant_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.applicant_name')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="applicant_name_bn"
                                                v-model="formData.applicant_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Designation (En)" vid="applicant_designation"  rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="applicant_designation"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.applicant_designation')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="applicant_designation"
                                                  v-model="formData.applicant_designation"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Applicant Designation (Bn)" vid="applicant_designation_bn"  rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="applicant_designation_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.applicant_designation')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="applicant_designation_bn"
                                                v-model="formData.applicant_designation_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Mobile No" vid="mobile" rules="required|min:11|max:11">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="mobile"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    id="mobile"
                                                    v-model="formData.mobile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Email" vid="email" rules="required|email|min:3">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="email"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="email"
                                                v-model="formData.email"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12" v-if="formData.p_profession_type !== 3">
                                            <ValidationProvider name="Organization Name (En)" vid="institution_name" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="institution_name"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.name_institution')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="institution_name"
                                                  v-model="formData.institution_name"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12" v-if="formData.p_profession_type !== 3">
                                            <ValidationProvider name="Organization Name (Bn)" vid="institution_name_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="institution_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.name_institution')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="institution_name_bn"
                                                v-model="formData.institution_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Address of Individuals/Institution (En)" vid="address"  rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="address"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.address_individuals_institution')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="address"
                                                  v-model="formData.address"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Address of Individuals/Institution (Bn)" vid="address_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="address_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.address_individuals_institution')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="address_bn"
                                                v-model="formData.address_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                    </div>
                                  </b-row>
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                      <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('portal.training_information')}}</legend>
                                        <b-row class="p-3">
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Training Title (En)" vid="training_title" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="training_title"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.training_title')}} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  id="training_title"
                                                  v-model="formData.training_title"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Training Title (Bn)" vid="training_title_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="training_title_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.training_title')}} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="training_title_bn"
                                                v-model="formData.training_title_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <!-- <b-col lg="6" sm="12">
                                            <ValidationProvider name="Start Date" vid="start_date" rules="required">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="start_date"
                                                  slot-scope="{ valid, errors }"
                                              >
                                                  <template v-slot:label>
                                                  {{$t('portal.training_start_date')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-input
                                                  class="fromDate"
                                                  v-model="formData.start_date"
                                                  :placeholder="$t('globalTrans.select_date')"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="End Date" vid="end_date" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="end_date"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('portal.training_end_date')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                class="fromDate"
                                                v-model="formData.end_date"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                          </b-col> -->
                                        </b-row>
                                        <b-row>
                                          <div class="col-12 pl-4 pr-4">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th class="text-center" style="width:30%">{{$t('portal.training_start_date')}} <span class="text-danger">*</span></th>
                                                  <th class="text-center" style="width:30%">{{$t('portal.training_end_date')}}</th>
                                                  <th class="text-center" style="width:20%">{{$t('portal.slot')}}</th>
                                                  <th class="text-center" style="width:20%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_date, index1) in formData.date_details" :key="index1">
                                                <td>
                                                  <ValidationProvider :vid="'start_date' + index1" rules="required"  name="Start Date">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        class="fromDate"
                                                        v-model="trainee_date.start_date"
                                                        :id="'date_details['+index1+'][start_date]'"
                                                        :placeholder="$t('globalTrans.select_date')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'end_date' + index1" rules="required"  name="End Date">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        class="fromDate"
                                                        v-model="trainee_date.end_date"
                                                        :id="'date_details['+index1+'][end_date]'"
                                                        :placeholder="$t('globalTrans.select_date')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td class="text-center">
                                                  {{ $n(index1 + 1) }}
                                                </td>
                                                <td class="text-center">
                                                  <button @click="removeDate(index1, trainee_date)" class="btn btn-sm btn-danger" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="4">
                                                  <button
                                                    @click="addMoreDate"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        </b-row>
                                      </fieldset>
                                    </div>
                                  </b-row>
                                  <b-row>
                                    <div class="col-md-12 dam-form">
                                      <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('portal.trainee_information')}}</legend>
                                          <div class="col-12" v-if="formData.p_profession_type === 1">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:20%">{{$t('portal.user_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('portal.grade_from')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('portal.grade_to')}} <span class="text-danger">*</span></th>
                                                  <th style="width:12%">{{$t('portal.male')}} <span class="text-danger">*</span></th>
                                                  <th style="width:12%">{{$t('portal.female')}}</th>
                                                  <th style="width:12%">{{$t('globalTrans.total')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_data, index) in formData.trainee_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'user_type_id' + index" rules="required|min_value:1" name="User Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.user_type_id"
                                                        :options="userTypeList"
                                                        :id="'trainee_details['+index+'][user_type_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <div v-if="trainee_data.user_type_id !== 3">
                                                    <ValidationProvider :vid="'grade_from' + index" rules="required|min_value:1" name="Grade Form">
                                                      <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          v-model="trainee_data.grade_from"
                                                          :options="gradeList"
                                                          :id="'trainee_details['+index+'][grade_from]'"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                        <div class="error invalid-feedback" role="alert">
                                                          {{ errors[0] }}
                                                        </div>
                                                      </div>
                                                    </ValidationProvider>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div v-if="trainee_data.user_type_id !== 3">
                                                    <ValidationProvider :vid="'grade_to' + index" rules="required|min_value:1"  name="Grade to">
                                                      <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          v-model="trainee_data.grade_to"
                                                          :options="gradeList"
                                                          :id="'trainee_details['+index+'][grade_to]'"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                        <div class="error invalid-feedback" role="alert">
                                                          {{ errors[0] }}
                                                        </div>
                                                      </div>
                                                    </ValidationProvider>
                                                  </div>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'male_trainee' + index"  rules="required|min_value:1"  name="Male Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.male_trainee"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :id="'trainee_details['+index+'][male_trainee]'"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.male_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'female_trainee' + index" rules="" name="Female Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.female_trainee"
                                                        :id="'trainee_details['+index+'][female_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.female_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_trainee' + index" name="Total Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.total_trainee"
                                                        :id="'trainee_details['+index+'][total_trainee]'"
                                                        disabled
                                                        :placeholder="$t('portal.total_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment(index, trainee_data)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7">
                                                  <button
                                                    @click="addMore"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                          <div class="col-12" v-if="formData.p_profession_type === 2 || formData.p_profession_type === 3">
                                            <table class="table" style="width:100%" border="1">
                                              <thead class="thead">
                                                <tr>
                                                  <th style="width:22%">{{$t('portal.user_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('portal.officer_type')}} <span class="text-danger">*</span></th>
                                                  <th style="width:18%">{{$t('portal.male')}}</th>
                                                  <th style="width:18%">{{$t('portal.female')}}</th>
                                                  <th style="width:18%">{{$t('portal.total_trainee')}}</th>
                                                  <th style="width:8%"></th>
                                                </tr>
                                              </thead>
                                              <tr v-for="(trainee_data, index) in formData.trainee_details" :key="index">
                                                <td>
                                                  <ValidationProvider :vid="'user_type_id' + index" rules="required|min_value:1" name="User Type">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-select
                                                        plain
                                                        v-model="trainee_data.user_type_id"
                                                        :options="userTypeList"
                                                        :id="'trainee_details['+index+'][user_type_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <div v-if="trainee_data.user_type_id !== 3">
                                                    <ValidationProvider :vid="'officer_type' + index" rules="required|min_value:1"  name="Officer Type">
                                                      <div slot-scope="{ valid, errors }">
                                                        <b-form-select
                                                          plain
                                                          v-model="trainee_data.officer_type"
                                                          :options="officerTypeList"
                                                          :id="'trainee_details['+index+'][officer_type]'"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                        <div class="error invalid-feedback" role="alert">
                                                          {{ errors[0] }}
                                                        </div>
                                                      </div>
                                                    </ValidationProvider>
                                                  </div>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'male_trainee' + index"  rules="required|min_value:1"  name="Male Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.male_trainee"
                                                        :id="'trainee_details['+index+'][male_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.male_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'female_trainee' + index" rules="" name="Female Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.female_trainee"
                                                        :id="'trainee_details['+index+'][female_trainee]'"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        @input="totalTrainee(trainee_data)"
                                                        :placeholder="$t('portal.female_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <ValidationProvider :vid="'total_trainee' + index" name="Total Trainee">
                                                    <div slot-scope="{ valid, errors }">
                                                      <b-form-input
                                                        v-model="trainee_data.total_trainee"
                                                        :id="'trainee_details['+index+'][total_trainee]'"
                                                        disabled
                                                        :placeholder="$t('portal.total_trainee')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input>
                                                      <div class="error invalid-feedback" role="alert">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </div>
                                                  </ValidationProvider>
                                                </td>
                                                <td>
                                                  <button @click="removeAttachment(index, trainee_data)" class="btn btn-sm btn-danger float-right" type="button">
                                                    <i class="fas fa-window-close m-0"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="6">
                                                  <button
                                                    @click="addMore"
                                                    class="btn btn-sm btn-primary float-right"
                                                    type="button"
                                                  ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                      </fieldset>
                                    </div>
                                  </b-row>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                      <b-button type="submit" variant="primary" class="mr-2">{{ $t('portal.submit') }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                              </ValidationObserver>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                  </iq-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'
// import { EventBus } from '@/EventBusLayout'
// const today = new Date().toISOString().substr(0, 10)

export default {
    mixins: [ModalBaseMasterList],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
        return {
          showMessage: false,
          headerText: { text_en: '', text_bn: '' },
          loading: false,
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('portal.submit'),
          formData: {
            id: '',
            p_profession_type: 0,
            applicant_name: '',
            applicant_name_bn: '',
            mobile: '',
            email: '',
            institution_name: '',
            institution_name_bn: '',
            applicant_designation: '',
            applicant_designation_bn: '',
            address: '',
            address_bn: '',
            training_title: '',
            training_title_bn: '',
            // start_date: '',
            // end_date: '',
            status: 1,
            trainee_details_remove: [],
            trainee_details: [
              {
                id: '',
                user_type_id: 0,
                officer_type: 0,
                grade_from: 0,
                grade_to: 0,
                male_trainee: 0,
                female_trainee: 0,
                total_trainee: 0
              }
            ],
            date_details: [
              {
                id: '',
                start_date: '',
                end_date: '',
                slot: ''
              }
            ]
          }
        }
    },
    watch: {
      // 'formData.trainee_details.male_trainee': function (newValue) {
      //   this.totalTrainee()
      // },
      // 'formData.trainee_details.female_trainee': function (newValue) {
      //   this.totalTrainee()
      // }
    },
    computed: {
      userTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Participant' : 'প্রশিক্ষণার্থী' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Guest' : 'অতিথি' }
        ]
      },
      professionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government Organization' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Organization', text_bn: 'সরকারি প্রতিষ্ঠান' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Private Organization' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Organization', text_bn: 'বেসরকারি প্রতিষ্ঠান' }
          // { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
        ]
        return professionTypeList
      },
      officerTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা' }
        ]
      },
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      gradeList: function () {
        return this.$store.state.Portal.commonObj.gradeList.filter(item => item.status === 0)
      }
    },
    async created () {
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      traineeDetailsReset () {
        this.formData.trainee_details = [{
          id: '',
          user_type_id: 0,
          officer_type: 0,
          grade_from: 0,
          grade_to: 0,
          male_trainee: 0,
          female_trainee: 0,
          total_trainee: 0
        }]
        // this.formData.trainee_details = []
        this.$refs.form.reset()
      },
      addMore () {
        this.formData.trainee_details.push({
          id: '',
          user_type_id: 0,
          officer_type: 0,
          grade_from: 0,
          grade_to: 0,
          male_trainee: 0,
          female_trainee: 0,
          total_trainee: 0
        })
      },
      removeAttachment (index, data) {
        this.formData.trainee_details.splice(index, 1)
        // this.formData.trainee_details_remove.push(data)
      },
      addMoreDate () {
        this.formData.date_details.push({
          id: '',
          start_date: '',
          end_date: '',
          slot: ''
        })
        // After adding new date details, initialize flatpickr on the new date inputs
        this.$nextTick(() => {
          flatpickr('.fromDate', {})
        })
      },
      removeDate (index, data) {
        this.formData.date_details.splice(index, 1)
        // this.formData.date_details.push(data)
      },
      totalTrainee (data) {
        let totalTrainee = 0
        totalTrainee = parseInt(data.male_trainee) + parseInt(data.female_trainee)
        data.total_trainee = totalTrainee
        return data
      },
      async createData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        result = await RestApi.postData(trainingElearningServiceBaseUrl, 'portal/sponsored-training-request/store', this.formData)

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push({
            path: '/training/sponsored-training-details',
            query: { id: result.data.id }
          })
          this.formData = {}
          this.$refs.form.reset()
          this.showMessage = true
        } else {
          this.$refs.form.setErrors(result.errors)
        }
        this.loading = false
      }
    }
}
</script>
<style>
  .alert .close {
    color: red !important;
  }
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 16px!important;
    padding: 6px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
</style>
